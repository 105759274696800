<template>
  <input
    :id="targetForIdFocus"
    v-model="model"
    type="number"
    placeholder="0"
    class="custom-input-table float-right"
    @keypress="onlyNumber"
  >
</template>

<script>

import helpers from '@/helpers'

export default {
  props: {
    qtdDoses: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      qtdLocal: 0,
      targetForIdFocus: this.$helpers.geraNumeroAleatorio().toString(),
    }
  },

  computed: {
    model: {
      get() {
        return this.getValue
      },
      set(value) {
        value = this.$helpers.somenteNumeros(value)
        this.qtdLocal = Number(value)
        this.$emit('input', this.qtdLocal)
      },
    },

    getValue() {
      return this.qtdLocal
    },
  },

  beforeMount() {
    this.qtdLocal = this.qtdDoses
  },

  methods: {
    onlyNumber(event) {
      if (!helpers.keyIsNumber(event)) {
        event.preventDefault()
      }
    },
  },
}
</script>
