import $store from '@/store'

// getters vuex
export function AdesaoNacionalGetState() {
  return $store.getters['adesaoNacionalState/state']
}

export function GetChooseCampaignAdesaoNacional() {
  return $store.getters['adesaoNacionalState/getChooseCampaignAdesaoNacional']
}
export function GetChooseCompanyAdesaoNacional() {
  return $store.getters['adesaoNacionalState/getChooseCompanyAdesaoNacional']
}
export function GetChooseOperadorAdesaoNacional() {
  return $store.getters['adesaoNacionalState/getChooseOperadorAdesaoNacional']
}

export function GetDistribuicaoDosesAdesaoNacional() {
  return $store.getters['adesaoNacionalState/getDistribuicaoDosesAdesaoNacional']
}

export function GetAdesoesEnderecoEmpresa() {
  return $store.getters['adesaoNacionalState/getAdesoesEnderecoEmpresa']
}

export function GetPayloadAdesaoNacional() {
  return $store.getters['adesaoNacionalState/getPayloadAdesaoNacional']
}

// setters vuex
export function AdesaoNacionalSetInit(payload) {
  $store.dispatch('adesaoNacionalState/setInit', payload)
}
export function SetChooseCampaignAdesaoNacional(campaignAdesaoNacional) {
  $store.dispatch('adesaoNacionalState/setChooseCampaignAdesaoNacional', campaignAdesaoNacional)
}
export function SetChooseCompanyAdesaoNacional(companyAdesaoNacional) {
  $store.dispatch('adesaoNacionalState/setChooseCompanyAdesaoNacional', companyAdesaoNacional)
}
export function SetChooseOperadorAdesaoNacional(operadorAdesaoNacional) {
  $store.dispatch('adesaoNacionalState/setChooseOperadorAdesaoNacional', operadorAdesaoNacional)
}
export function SetAddressCompanyAdesaoNacional(addressCompany) {
  $store.dispatch('adesaoNacionalState/setAddressCompanyAdesaoNacional', addressCompany)
}

export function SetAdesoesEnderecoEmpresa(adesoesEnderecoEmpresa) {
  $store.dispatch('adesaoNacionalState/setAdesoesEnderecoEmpresa', adesoesEnderecoEmpresa)
}

export function SetPayloadAdesaoNacional() {
  $store.dispatch('adesaoNacionalState/setPayloadAdesaoNacional')
}

export async function AdesaoUpdateDoseCompany(idEnderecoEmpresa, idEmpresa, idVacina, value) {
  await $store.dispatch('adesaoNacionalState/updateDose', {
    idEnderecoEmpresa,
    idEmpresa,
    idVacina,
    value,
  })
}
